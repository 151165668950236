.article-content p {
	margin-bottom: 2rem!important;
	text-align: justify;
}

.article-image-wrapper {
	/* margin-bottom: 2rem!important; */
	text-align: center;
}

.article-content img {
	width: auto!important;
	max-height:700px;
	max-width: 100%!important;
}

.article-content a {
	color: #247678!important;
}

figure > img {
	width: 100%!important;
}

.article-content img + em {
	font-size: smaller!important;
    color: grey!important;
    display: block!important;
	/* padding-bottom: 4rem!important; */
	margin-bottom: 2rem!important;
	text-align: center!important;
}