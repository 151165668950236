.nav-pills {
    --bs-nav-pills-link-hover-border-color: #247678!important;
}

.nav-pills .nav-link {
    border-radius: 30px!important;
    color: #247678!important;
}

.nav-link:hover {
    background-color: 'red';
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #247678!important;
    color: #fff!important;
}

h1.report-title {
    font-size: 36px!important;
    margin-top: 40px!important;
}