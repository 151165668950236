.nav-pills .nav-link {
    border-radius: 30px!important;
    color: #247678!important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #247678!important;
    color: #fff!important;
}

.legend-class {
    display: flex;
    flex-direction: column;
}

.waterPane .leaflet-tile-loaded {
    filter: hue-rotate(25deg) brightness(85%) saturate(1000%);
    opacity: 1;
    /* mix-blend-mode: darken; */
}

.leaflet-bottom {
	z-index: 998!important;
}

.legenda {
    width: 60%;
    padding: 0 20px;
}