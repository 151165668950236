a.page-link {
    color: #247678!important;
}

.active>a.page-link {
    background-color: #247678!important;
    color: #fff!important;
    border-color: #247678!important;
}

.disabled>a.page-link {
    color: #6c757d!important;
}

#button-search {
    color: #247678!important;
    border-color: #247678!important;
}

#button-search:hover {
    color: #fff!important;
    background-color: #247678!important;
    border-color: #247678!important;
}

.list-unstyled a {
    color: #247678!important;
}