.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html {
	overflow-y: scroll;
}

/* .container-content {
  position: relative;
} */

/* @media (min-width: 992px){
  .container-content {
    width: 970px !important;
  }
}
@media (min-width: 768px){
  .container-content {
    width: 750px;
  }
} */


.citiesPane {
  z-index: 550 !important;
}

.waterPane {
  z-index: 570 !important;
}

.statePane {
  z-index: 600 !important;
}

.labelsPane {
  z-index: 610 !important;
}