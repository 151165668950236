/* .container {
    width: 1000px;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
} */
img.flag-score {
    max-width:100%;
}

.scorecard h1 {
    color: #4f4f4f;
}

.sub {
    color: #4f4f4f!important;
    line-height: 1.75rem;
}

.scorecard .footer-logo {
    width: 100%;
    padding: 0 0px;
    filter: grayscale(0)
}

.scorecard .footer-logo.bg-remove {
    mix-blend-mode: darken;
}

.city-title {
    display: inline-block;
    border: none;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.1;
    /* margin: 0px 0px 1.875rem; */
    padding-right: 1.875rem;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBlZHLCYAwEES3BEuwlBw9JDJg3LMl2IF2YAt2YikpwRJ0RfwgiUse5JDDY4cZsvBzjXbLeYdDFVDKZ80QV4hDBxY8ZFwb6cIARQ0OusiBvlg00ESHtqMYDn75kRZKIflNSrwLSeHAU7R+jbOo9zwc1GtP5GceSdBTDuc8kfr1q94k6xd22oiusWHAY2MAAAAASUVORK5CYII=") 100% center no-repeat transparent;
    text-decoration: none;
    text-align: left;
    color: #4f4f4f;
    background-color: transparent;
    cursor: pointer;
}

.city-title-print {
    display: inline-block;
    border: none;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.1;
	text-decoration: none;
    text-align: left;
    color: #4f4f4f;
    background-color: transparent;
}

.border-top {
    margin-top: 15px!important;
	border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
  }

.scorecard .sub{
    border-bottom: 1pt solid rgba(34,34,33,0.5);
    margin-bottom: 15px;
}

.scorecard .dimension {
    border-top: 1pt solid #e2e7e7;
    margin-top: 10px;
}

.scorecard .leftBar{
    border: 1px solid #e2e7e7;
    border-bottom: 0px solid #e2e7e7;
}

.scorecard .row .scoreips{
    text-align: right;
}

.scoreips{
	font-size: 12px;
}

@media (max-width: 992px){
	.main-score {
	  margin-top: 30px!important;
	}
  }

.scoreblock {
    background-color: rgba(178,207,208,0.1);
    border-top: 6pt solid rgba(178,207,208,1);
    border-bottom: 6pt solid rgba(178,207,208,1);
}

@media (min-width: 992px) {
    .col-md-border:not(:last-child) {
        border-right: 2pt solid rgba(178,207,208,1);
        padding-bottom: 15px;
    }
    .col-md-border + .col-md-border {
        border-left: 2pt solid rgba(178,207,208,1);
        margin-left: -1px;
        padding-bottom: 15px;
    }
	.big-badge {
		max-height: 4rem!important;
	}
}

/* .scorecard .row .p {
    margin-bottom: 0px!important;
} */

.scorecard .row .scoretitle {
    margin-bottom: 10px;
    padding: 25px 0px 0px 0px;
}

/* .scorecard .row .scoreblock {
    margin: 0px;
    padding: 25px 0 0px;
} */

.scorecard .row .firstsub {
    /* margin: 0px; */
    padding: 25px 0px 0px 0px;
}

.badge {
    display: inline-block!important;
    /* float: right; */
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    background-color: #999999;
    border-radius: 10px;
}

.big-badge {
    display: inline-block!important;
    /* float: right; */
    width: 4rem;
    height: 4rem;
	max-height: 40px;
    /* padding: 3px 7px; */
    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 1;
    margin-top: auto;
    margin-bottom: 0px;
    white-space: normal;
    text-align: center;
    background-color: #999999;
    border-radius: 10px;
}




.badge:empty {
    display: inline-block!important;
    /* float: right; */
    width: 60%;
    height: 18px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 1;
    margin-top: 3px;
    margin-bottom: 3px;
    white-space: normal;
    text-align: center;
    background-color: #999999;
    border-radius: 10px;
}

.green-link a {
    color: #247678!important;
}