.top {
    background-color: #247678 !important;
    padding-top: 15px !important;
    padding-bottom: 15px;
  }

  .btn-outline-language {
    --bs-btn-color: #247678!important;
    --bs-btn-border-color: #247678!important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #17656c;
    --bs-btn-hover-border-color: #17656c!important;
    --bs-btn-focus-shadow-rgb: 33,37,41;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #17656c;
    --bs-btn-active-border-color: #17656c!important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none;
}

.btn-outline-language:hover {
  color: #fff!important;
  background-color: #247678!important;
  border-color: #0d7682!important;
}
  
  .leaflet-container a.btn{
    text-decoration: none;
    color: var(--bs-btn-color);
}

.leaflet-popup-content {
  font-size: 16px;
}

  .top .top-header-block {
      display: flex;
      flex-direction: column;
      height: 160px;
      align-items: center;
      justify-content: space-between;
  }

a.active{
  color: #247678!important;
  font-size: 105%!important;
  font-weight: 600!important;
}

/* #root {
  position: relative;
  min-height: 100vh;
} */

#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #ebebeb;           /* Footer height */
}

#site-container {
  min-height: calc(100vh - 168px);
}

#footer p {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 600;
}

.footer-logo {
  width: 50%;
  padding: 0 20px;
  filter: grayscale(100%);
}

.footer-logo-sm {
  width: 33%!important;
}

@media (min-width: 992px){
  .border-right {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
  }
}

@media (max-width: 992px){
  .border-right {
    margin-bottom: 30px;
  }
}

.footer-logo.bg-remove {
  mix-blend-mode: darken;
}

.footer-logo:hover{
  filter: grayscale(0);
  size: 105%;
}

.top .top-header-block {
  display: flex;
  flex-direction: row;
  height: 160px;
  justify-content: center;
  align-items: center;
}
    /* .container {
        width: 750px;
    }
    .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    } */
  
  /* iframe.full-iframe {
    width: 100%;
    height: 400px;
  }
  
  .navbarips {
    border-radius: 0;
    padding-left: 100px;
  }
  .navbar-custom {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
  }
  @media (min-width: 768px){
  .navbarips {
    border-radius: 4px;
  }}
  .navbarips {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
  }
  
  .navbar-header {
    float: left;
  }
  
  .navbar-custom .navbar-collapse, .navbar-custom .navbar-form {
    border-color: #e7e7e7;
  }
  
  @media (min-width: 768px){
    .navbar-collapse.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }
    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none;
    }
  }
  
  @media (min-width: 768px){
  .navbar-nav-custom {
      float: left;
      margin: 0;
  }}
  .navbar-nav-custom {
      margin: 7.5px -15px;
  }
  .navcustom {
      margin-bottom: 0;
      padding-left: 40px;
      list-style: none;
  }
  
  .navbar-collapse {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    -webkit-overflow-scrolling: touch;
  }
  .collapse {
    display: none;
  }
  
  @media (min-width: 768px){
  .navbar-nav-custom>li {
      float: left;
  }}
  .navcustom>li {
      position: relative;
      display: block;
  }
  
  .navbarips.navbar-custom .navbar-nav-custom>li>a {
    color: #00929f;
  }
  .navbar-custom.navbar-nav-custom>li>a {
    color: #777777;
  }
  .navbarips li a {
    text-decoration: none;
    font-size: 20px;
    padding: 34px;
  }
  @media (min-width: 768px){
  .navbar-nav-custom>li>a {
    padding-top: 15px;
    padding-bottom: 15px;
  }}
  .navbar-nav-custom>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }
  .navcustom>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
  } */