img.capa-publicacao {
    height: 290px !important;
    max-width: 320px!important;
    margin: 20px 0!important;
}

.report {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

h2 {
    color: #5e6469;
    font-size: 22px !important;
}

small {
    color: #999999;
    font-size: 18px !important;
}

.btn-outline-primary {
    color: #0d7682!important;
    border-color: #0d7682!important;
    border-radius: 15px!important;
    margin-top: auto!important;
    font-weight: 500!important;
}

.btn-outline-primary:hover {
    color: #fff!important;
    background-color: #17656c!important;
    border-color: #0d7682!important;
}